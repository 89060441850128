import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import "./styles.scss"

import Layout from "../components/layout"
import Image from "../components/image"
import Img from "gatsby-image"
import SEO from "../components/seo"
import ModalButton from "../components/modal"
import TitleCard from "../components/titleCard"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <section class="hero is-small">
        <div class="hero-body">
          <div class="container has-text-centered">
            <h1 class="title">
              Nähkurse, Handgemachtes und Dekoration 
      </h1>
            <h2 class="subtitle">
              Bei mir können nähbegeistere Anfänger und Fortgeschrittene ihre Fähigkeiten ausbauen. 
      </h2>
      <Link class="button is-primary" to="/kontakt/" state={{ typeRequest: "allgemein" }}>Kontakt aufnehmen</Link>

          </div>
        </div>
      </section>
      <section className="section">
        <div className="kurse_home">
          <div className="columns">
            <div className="column">
              <TitleCard
                title="Nähkurse"
                content="Ich biete unterschiedliche Kurse für verschiedene Leistungsgruppen an. Die Kurse biete ich in Neuburg an."
                image={
                  <Img
                    fluid={data.naehkursImage.childImageSharp.fluid}
                    alt="Nähkurse in Ingolstadt und Neuburg" />
                }
                button={<Link className="button is-primary" to="/kurse/">Lernen</Link>}
              />
            </div>
            <div className="column">
              <TitleCard 
              title="Handgemachtes" 
              content="Eine Auswahl an Handgemachtem - Lass dich inspirieren" 
              image={<Img fluid={data.handgemachtesImage.childImageSharp.fluid} alt="bla" />}
              button={<Link className="button is-primary has-text-weight-semibold" to="/werke/">Stöbern</Link>}
              />
            </div>
            <div className="column">
              <TitleCard 
              title="Dekoration" 
              content="Dekoration für Hochzeit, Beerdigungen und Feste aller Art." 
              image={<Img
                fluid={data.dekoImage.childImageSharp.fluid}
                alt="bla"
              />}
              button={<Link className="button is-primary has-text-weight-semibold" to="/werke/" disabled>Bald mehr</Link>}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );

}

export const query = graphql`
  query {
    title: markdownRemark(frontmatter: {id: {eq: 1}}) {
      frontmatter {
        title
      }
    }
    naehkursImage: file(relativePath: { eq: "naehmaschine.JPG"}) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid
        }
      }
    }
    handgemachtesImage: file(relativePath: { eq: "tasche_title.JPG"}) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid
        }
      }
    }
    dekoImage: file(relativePath: { eq: "deko_title.JPG"}) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `

export default IndexPage
