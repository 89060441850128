import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"


const TitleCard = (props) => {
    
    return (
        <div class="card bm--card-equal-height">
            <div class="card-image">
                {props.image}
            </div>
            <div class="card-content">
                <div class="media">
                    <div class="media-content">
                        <h4 class="title is-4">{props.title}</h4>
                    </div>
                </div>
                <div class="content is-size-5">
                    {props.content}
                </div>
                {props.button}
            </div>
        </div>
    )
}

TitleCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string
}

export default TitleCard